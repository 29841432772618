import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyInfoService {
  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  getCompanyInfo(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/companyInfo'), { headers });
  }

  updateCompanyInfo(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/editCompanyInfo'), data, { headers });
  }

  // obtener las facturas generadas a las empresas (servicarga a todas)
  getAllInvoice(){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getAllInvoice'), { headers });
  }

  downloadDeliveryReport(fileId){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });

    const params = new HttpParams().set('file_id', fileId);
    return this.http.get(this.configService.serverIp.concat('/downloadFile'), {headers, params});
  }


  getReportDeliveredEnterprise() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getReportDeliveredEnterprise'), { headers });
  }
}
